<div class="nav__user">
  <div class="nav__user__wrapper">
    <div class="nav__user__title">
      <span class="nav__user__title__logged">{{"USER.LOGGED_IN_AS" | translate}}</span>
      <span class="nav__user__title__name" *ngIf="user">{{ user.first_name }} {{user.last_name}}</span>
    </div>
    <button class="nav__user__avatar" (click)="toggleMenu()">
        <img [src]="userAvatar ? userAvatar :  'assets/img/no-user-profile.svg'"
             class="nav__user__avatar__image"
             alt="avatar">
    </button>
  </div>
  <leaderio-context-menu
    [(open)]="menuOpen">
    <button class="nav__user__menu__user"
            (click)="onUser()">
      <svg-icon [src]="userAvatar"
                [svgClass]="'user__icon'"></svg-icon>
      <span>{{"USER.MY_ACCOUNT" | translate}}</span>
    </button>
    <!-- <button class="nav__user__menu__logout"
            (click)="onURL()">
      <svg-icon src="assets/img/icons/info.svg" [svgClass]="'logout__icon'"></svg-icon>
      <span>{{"TERMS_AND_CONDITIONS" | translate}}</span>
    </button>
    <button class="nav__user__menu__logout"
            (click)="onLink('privacy')">
      <svg-icon src="assets/img/icons/lock.svg" [svgClass]="'logout__icon'"></svg-icon>
      <span>{{"PRIVACY_POLICY" | translate}}</span>
    </button> 
    <button class="nav__user__menu__logout"
            (click)="onLink('license')">
      <svg-icon src="assets/img/icons/document-chart.svg" [svgClass]="'logout__icon'"></svg-icon>
      <span>{{"LICENSES" | translate}}</span>
    </button> -->
    <button class="nav__user__menu__logout"
            (click)="onLogout()">
      <svg-icon src="assets/img/icons/logout.svg" [svgClass]="'logout__icon'"></svg-icon>
      <span>{{"USER.LOGOUT" | translate}}</span>
    </button>
  </leaderio-context-menu>
</div>

