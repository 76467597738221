import {AfterViewInit, Component} from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "../../../../services/auth/auth.service";
import {User} from "../../../../../graphql/generated";

@Component({
  selector: 'leaderio-user-auth-display',
  templateUrl: './userAuthDisplay.component.html',
  styleUrls: ['./userAuthDisplay.component.scss'],
})
export class UserAuthDisplayComponent implements AfterViewInit{
  menuOpen: boolean = false;
  user: User | undefined;
  userAvatar = 'assets/img/icons/user.svg';

  constructor(private router:Router,
              private authService: AuthService) {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.authService.user.subscribe((user) => {
          this.user = user;
          if(user?.avatar_url) {
            this.userAvatar = user.avatar_url;
          }
          if (!user?.avatar_url) {
            this.userAvatar = 'assets/img/icons/user.svg';
          }
      });
    }, 0)
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  onLogout() {
    this.authService.logout();
    this.toggleMenu();
    this.router.navigate(['/auth/sign-in']);
  }

  onUser() {
    this.toggleMenu();
    this.router.navigate(['/settings']);
  }

  onLink(link: 'license' | 'terms-conditions' | 'privacy') {
    this.toggleMenu();
    this.router.navigate([`/${link}`]);
  }

  onURL(url: string) {
    this.toggleMenu();
    window.open(url, "_blank");
  }
}
